<template>
  <ClientTransferRequest :operation="'W'" />
</template>

<script>
import ClientTransferRequest from '@/views/clientRequest/Edit'
export default {
  components: {
    ClientTransferRequest
  },
  data() {
    return {

    }
  }
}
</script>

<style>

</style>