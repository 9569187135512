<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('transfer_request_observations') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            :label="$t('transfer_observations')"
            dense
            autocomplete="nope"
            v-model="clientRequest.permanentInstruction.bnfReference"
            maxlength="128"
            :disabled="clientRequest.permanentInstruction.accountType == 'nfiAccount'"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="$t('transfer_instructions')"
            dense
            autocomplete="nope"
            v-model="clientRequest.permanentInstruction.avsInternalRemark"
            maxlength="128"
            :disabled="clientRequest.permanentInstruction.accountType == 'nfiAccount'"
          />
        </v-col>
      </v-row>
    </template> 
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
export default {
  components: {
    WizardForm
  },
  props: {
    clientRequest: {
      type: [Object]
    }
  },
  data() {
    return {
      checkbox: false,
      stepTwo: {
        account_type: 'Cuentas de Bancos en Panamá'
      },
      items: [
        'Cuenta Corriente en Banco', 
        'Cuenta Ahorro en Banco', 
        'Cuenta Inversión en Institución Financiera no Banco'
      ],
      banks: [
        'Banco Mercantil', 
        'Banesco', 
      ],
    }
  }
}
// 'Cuentas de Bancos en Panamá'
// 'Cuentas de Bancos Fuera de Panamá'
// Cuentas de AV Securities Inc'
</script>
<style scoped>
.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 400px;
}
</style>